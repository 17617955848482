.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #00585d;
  color: #fff;
}
::selection {
  background: #00585d;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(0, 137, 144, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  border: 0.625rem solid #f3f3f3;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.7;
  margin: 0 auto;
  max-width: 160rem;
  text-rendering: geometricPrecision;
}
@media (min-width: 18.125rem) {
  body {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
@media (min-width: 35rem) {
  body {
    border: 0.9375rem solid #f3f3f3;
  }
}
@media (min-width: 61.875rem) {
  body {
    border: 2.5rem solid #f3f3f3;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.4375rem + 0.5
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 1.4375rem;
  color: #008990;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.85em;
  text-transform: uppercase;
}
@media (min-width: 18.125rem) {
  h1 {
    font-size: calc(1.4375rem + 0.5
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 1.9375rem;
  }
}
h2 {
  letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.85em;
  padding-top: 0.85em;
  text-transform: uppercase;
}
@media (min-width: 18.125rem) {
  h2 {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 1.5rem;
  }
}
h3 {
  letter-spacing: calc((1rem + 0.3125
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.85em;
  padding-top: 0.85em;
  text-transform: uppercase;
}
@media (min-width: 18.125rem) {
  h3 {
    font-size: calc(1rem + 0.3125
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.3125rem;
  }
}
h4 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.85em;
}
@media (min-width: 18.125rem) {
  h4 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 18.125rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.85em;
}
.main_color {
  color: #008990;
}
a {
  color: #5a5a5a;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip: ink;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #5a5a5a;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(0, 137, 144, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #5a5a5a;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #008990;
  display: inline-block;
  margin-bottom: 0.425em;
  outline-offset: 0;
  padding: 0;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #5a5a5a;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(0, 137, 144, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.85em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.7em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.7em;
}
table {
  border-bottom: 0.0625rem solid #a0a0a0;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #a0a0a0;
  padding: 0.56666667em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f3f3f3;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.85em;
  padding: 0;
}
ol > li {
  padding-left: 1.7em;
  position: relative;
}
ol > li:before {
  color: #008990;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.7;
  position: absolute;
  text-align: right;
  width: 1.275em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.85em;
  padding-left: 1.7em;
}
ul > li {
  line-height: 2;
  padding-left: 0.17em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #a0a0a0;
  margin: 0.85em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #a0a0a0;
  font-weight: 700;
  padding: 0.85em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.85em 0.85em 0.85em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #a0a0a0;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.85em 0.56666667em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav.steuernews_issues ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.85em;
  padding-left: 1.7em;
}
nav.steuernews_issues ul > li {
  line-height: 2;
  padding-left: 0.17em;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.7em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.7em;
}
figure.floatright {
  float: right;
  margin-left: 1.7em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.425em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #5a5a5a;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #9a9a9a;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #008990;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #008990;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.425em;
  padding: 0 0.425em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 18.125rem)
                                 / (93.75 - 18.125))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #5a5a5a;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.85em;
  padding: 0.85em 0 0;
  width: 100%;
}
@media (min-width: 18.125rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.425em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #a0a0a0;
  border-radius: 0;
  color: #5a5a5a;
  margin: 0 0 1.13333333em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #008990;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #008990;
  color: #5a5a5a;
}
.input:focus,
.textarea:focus {
  border-color: #008990;
  color: #5a5a5a;
}
.input {
  height: 2.75rem;
  padding: 0 0.85em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.7;
  overflow: hidden;
  padding: 0.85em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  margin: 0.425em 0.85em 0.85em 0;
}
@media (min-width: 30rem) {
  .control {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
  }
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #008990;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #008990;
  color: #5a5a5a;
}
.control input:focus ~ .indicator {
  border-color: #008990;
  color: #5a5a5a;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.85em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #a0a0a0;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #5a5a5a;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #a0a0a0;
  border-radius: 0;
  color: #5a5a5a;
  cursor: pointer;
  display: block;
  margin: 0 0 1.13333333em;
  padding: 0 0.85em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #5a5a5a;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.85em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #008990;
}
.select.error.focus {
  border-color: #008990;
  color: #5a5a5a;
}
.select.focus {
  border-color: #008990;
  color: #5a5a5a;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #5a5a5a;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #5a5a5a;
}
.select select:focus::-ms-value {
  background: none;
  color: #5a5a5a;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 137, 144, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 137, 144, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 137, 144, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 137, 144, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.7em;
  }
  * + [role="tablist"] {
    margin-top: 1.7em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #a0a0a0;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.425em;
    min-width: 100%;
    padding: 0.56666667em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.85em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #008990;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #008990;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #008990;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #008990;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.7em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.55em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .company_name .addition {
    font-weight: 300;
    text-transform: none;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 1.25rem;
  }
  .address_vcard_button {
    margin: 0.85em 0;
  }
  .address_call_button {
    margin: 0.85em 0;
  }
  aside .teaser_overview .teaser {
    background: #F1F8F8;
    padding: 1.40625rem;
    text-align: center;
  }
  aside .teaser_overview .teaser h2 {
    padding-top: 0;
  }
  aside .teaser_overview .teaser .button {
    margin-top: 0.85em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_ver {
    display: inline-block;
    fill: #a0a0a0;
    height: 4.6875rem;
    left: 1.4375rem;
    line-height: 4.6875rem;
    position: absolute;
    top: 1.5rem;
    width: 0.5625rem;
  }
  .breadcrumbs {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 0.85em;
  }
  .breadcrumbs p {
    margin: 0 0.425em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.425em;
  }
  .button {
    border-radius: 0;
    font-size: 1rem;
    padding: 0 1.7em;
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .button .icon + .text {
    padding-left: 0.56666667em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    outline: 0;
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #008990 inset;
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.56666667em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 18.125rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #008990;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.425em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #008990;
    height: auto;
    left: 0.425em;
    top: 0.425em;
    width: auto;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #f3f3f3;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #a0a0a0;
    border-top: 0;
  }
  .logo_wrapper {
    padding: 1.7em 0.85em;
    text-align: right;
  }
  .logo_wrapper .company_logo {
    max-width: 12.5rem;
    display: inline-block;
    min-width: 15.625rem;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f3f3f3;
    border-top: 0.0625rem solid #a0a0a0;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.13333333em 0.85em 1.13333333em 1.7em;
  }
  .cookie_banner .close {
    cursor: pointer;
    margin-right: 0.85em;
    padding: 0 0.85em;
  }
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.34em;
  }
  .download_overview {
    margin-bottom: 0.85em;
  }
  .download_overview .entry {
    border-bottom: 0.0625rem solid #f3f3f3;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 1.7em;
  }
  .download_overview .entry .icon {
    display: inline-block;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .download_overview .entry:hover .title {
    color: #5a5a5a;
  }
  .download_overview .entry:hover .icon {
    -webkit-transform: translateY(0.3125rem);
            transform: translateY(0.3125rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .download_overview a.touch_link .title {
    text-decoration: none;
  }
  .download_overview .icon {
    flex-shrink: 0;
    fill: #5a5a5a;
    height: 1.5rem;
    margin-right: 0.85em;
    width: 1.5rem;
  }
  .navigation_wrapper .position_end {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu a {
    text-decoration: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    position: relative;
    z-index: 99;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: end;
            align-items: flex-end;
    position: relative;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    color: #008990;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    max-width: 21.875rem;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    font-weight: 400;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: end;
            justify-content: flex-end;
    padding: 0 0.85em 0.85em;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    color: #008990;
  }
  .dropdown_menu > ul > li ul {
    background: #f3f3f3;
    max-width: 0;
    overflow: hidden;
    padding: 1.7em 0;
    position: absolute;
    right: 102%;
    top: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    max-width: 25rem;
  }
  .dropdown_menu > ul > li ul li {
    padding: 0 0.85em;
  }
  .dropdown_menu > ul > li ul a {
    color: #5a5a5a;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-bottom: 0.425em;
    padding: 0 0.425em;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #008990;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #008990;
  }
  .dropdown_menu > ul > li ul a.line {
    border-bottom: 0.0625rem solid #fff;
    border-top: 0.0625rem solid #fff;
    margin: 0.85em 0;
    padding: 0.85em 0;
  }
  footer {
    background: #f3f3f3;
    margin-top: 1.875rem;
    padding: 3.4em 0 1.7em;
  }
  footer .related_links {
    margin: 0.85em 0;
  }
  footer .related_links ul {
    display: -webkit-box;
    display: flex;
  }
  footer .related_links ul li {
    margin-right: 1.7em;
  }
  footer .related_links ul li:last-child {
    margin-right: 0;
  }
  .form_disclaimer {
    background: #f3f3f3;
    border-radius: 0;
    color: #5a5a5a;
    font-size: 85%;
    margin-bottom: 0.85em;
    padding: 1.13333333em 1.7em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1.13333333em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #008990;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #a0a0a0;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #a0a0a0 inset;
    color: #5a5a5a;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    line-height: calc(2.75rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.85em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.7em;
    z-index: 10;
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.56666667em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #008990 inset;
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.56666667em;
  }
  button {
    cursor: pointer;
    height: 2.75rem;
    margin: 0.85em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.7em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    border-top: 0.0625rem solid #f3f3f3;
    margin: 1.7em 0 0.85em;
    padding-top: 1.7em;
  }
  .google_maps .map {
    background: #f3f3f3;
    border: 0.0625rem solid #f3f3f3;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps h2,
  .google_maps .required_description {
    text-align: center;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.85em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio,
  .google_maps .travel_mode input:hover ~ .travel_mode_radio {
    z-index: 5;
    background: #fff;
    border-color: #a0a0a0;
    color: #008990;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon,
  .google_maps .travel_mode input:hover ~ .travel_mode_radio .icon {
    fill: #008990;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    z-index: 15;
    background: #00bac3;
    border-color: #007177;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #a0a0a0;
    color: #5a5a5a;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #5a5a5a;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.56666667em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #5a5a5a;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #008990;
    font-size: 85%;
    margin-bottom: 0.85em;
    padding: 1.13333333em 1.7em;
  }
  .google_maps .direction {
    background: #f3f3f3;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.7em;
  }
  .google_maps .button_wrapper {
    margin-bottom: 1.7em;
    text-align: center;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.85em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #a0a0a0;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #a0a0a0;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.85em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.85em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.85em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 18.125rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  .row.row_reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .navigation_wrapper {
    margin-bottom: 0;
  }
  .header_picture {
    margin-top: -1.25rem;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .image_gallery_overview {
    margin-bottom: 1.7em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #f3f3f3;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.85em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #008990;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #008990;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #008990;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #5a5a5a;
    height: 2rem;
    width: 2rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.7em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.7em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #5a5a5a;
    font-family: "Merriweather Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #5a5a5a;
    font-weight: 700;
  }
  .arbeitnehmer_online_text {
    fill: #5a5a5a;
    font-family: "Merriweather Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #5a5a5a;
  }
  .arbeitnehmer_online_icon {
    fill: #008990;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #008990;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #008990;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #5a5a5a;
  }
  .arbeitnehmer_online_lines {
    fill: #008990;
  }
  .u_online_secure_login {
    margin-bottom: 1.7em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.7em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.7em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #5a5a5a;
    font-family: "Merriweather Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #5a5a5a;
    font-weight: 700;
  }
  .u_online_text {
    fill: #5a5a5a;
    font-family: "Merriweather Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #5a5a5a;
  }
  .u_online_warning_icon {
    fill: #5a5a5a;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #5a5a5a;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #008990;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #008990;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #008990;
  }
  .u_online_button:hover .u_online_icon {
    fill: #5a5a5a;
  }
  .u_online_lines {
    fill: #008990;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 18.125rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #008990;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #80c4c8;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.85em auto;
    max-width: 18.125rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.85em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.85em auto;
    max-width: 18.125rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.85em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.7em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #008990;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #f3f3f3;
    display: table;
    margin: 0 auto 0.85em;
    padding: 1.7em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.7em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #008990;
    border-left-width: 0.0625rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #5a5a5a;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 2.75rem;
    margin-right: 0.1875rem;
    padding: 0 1.7em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #008990;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    height: 2.75rem;
    position: relative;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    vertical-align: middle;
    width: 2.75rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #5a5a5a;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #5a5a5a;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #00585d;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #00585d;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #008990;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(0, 137, 144, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.85em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #008990;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #f3f3f3;
    border-radius: 0;
    color: #5a5a5a;
    font-size: 85%;
    margin-bottom: 0.85em;
    padding: 1.13333333em 1.7em;
  }
  .infolist_overview .entry {
    border-bottom: 0.0625rem solid #f3f3f3;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 1.7em;
  }
  .infolist_overview .entry span.main_color {
    display: inline-block;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .infolist_overview .entry:hover .title {
    color: #5a5a5a;
  }
  .infolist_overview .entry:hover span.main_color {
    -webkit-transform: translateX(0.1875rem);
            transform: translateX(0.1875rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .infolist_overview a.touch_link .title {
    text-decoration: none;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #f3f3f3;
    border-radius: 0;
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.7em;
    padding: 1.7em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.7em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #a0a0a0;
    margin-bottom: 0.85em;
    padding-top: 0.85em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .infolist_anchor_overview {
    background: #f3f3f3;
    border-radius: 0;
    padding: 0.85em 1.7em;
  }
  .infolist_anchor_overview a {
    display: block;
    padding: 0.85em 0;
  }
  .infolist_anchor_overview li {
    border-top: 0.0625rem solid #a0a0a0;
  }
  .infolist_anchor_overview li:first-child {
    border-top: 0;
  }
  .line_chart {
    border: 0.0625rem solid #a0a0a0;
    height: 25rem;
    margin-bottom: 1.7em;
  }
  .switch_view {
    margin-bottom: 0.85em;
  }
  .map_chart {
    border: 0.0625rem solid #a0a0a0;
    height: 28.75rem;
    margin: 0 0 1.7em;
    padding: 0.425em;
  }
  .jvectormap-label,
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    background: #f3f3f3;
    border: 0.0625rem solid #a0a0a0;
    color: #5a5a5a;
    position: absolute;
  }
  .jvectormap-label {
    border-radius: 0;
    display: none;
    padding: 0.425em 0.85em;
    white-space: nowrap;
    z-index: 9999;
  }
  .jvectormap-label .amount {
    display: inline;
  }
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    cursor: pointer;
    height: 2.5rem;
    left: 0.625rem;
    line-height: 2.5rem;
    text-align: center;
    width: 2.5rem;
  }
  .jvectormap-zoomin {
    border-radius: 0 0 0 0;
    top: 0.625rem;
  }
  .jvectormap-zoomout {
    border-radius: 0 0 0 0;
    top: 3.125rem;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.85em;
  }
  .startpage .picture_wrapper {
    margin: 0.46875rem 0 1.40625rem;
  }
  .startpage figure {
    margin-bottom: 0;
  }
  .startpage .figure {
    margin-bottom: -2.1875rem;
    position: relative;
    z-index: 99;
  }
  .startpage .teaser_overview .teaser {
    background: #008990;
    color: #fff;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 1.40625rem;
  }
  .startpage .teaser_overview .teaser h2 {
    color: #fff;
  }
  .startpage .teaser_overview .teaser .button {
    background: #fff;
    border: 0.0625rem solid #008990;
    color: #008990;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 0.85em;
    width: 100%;
  }
  .startpage .teaser_overview .teaser .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .startpage .teaser_overview .teaser .button .icon {
    fill: #008990;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .startpage .teaser_overview .teaser .button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .startpage .teaser_overview .teaser .button .icon + .text {
    padding-left: 0.56666667em;
  }
  .startpage .teaser_overview .teaser .button:hover {
    background: rgba(255, 255, 255, 0.8);
    border: 0.0625rem solid #008990;
    color: #008990;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .startpage .teaser_overview .teaser .button:hover > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .startpage .teaser_overview .teaser .button:hover .icon {
    fill: #008990;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .startpage .teaser_overview .teaser .button:hover .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .startpage .teaser_overview .teaser .button:hover .icon + .text {
    padding-left: 0.56666667em;
  }
  .job_entry {
    margin-bottom: 1.7em;
  }
  .newsletter_quick_subscribe {
    background: #F1F8F8;
    padding: 1.40625rem;
    text-align: center;
  }
  .newsletter_quick_subscribe h2 {
    padding-top: 0;
  }
  .newsletter_quick_subscribe form {
    display: block;
    margin: 1.7em auto 0;
    max-width: 48.75rem;
  }
  .newsletter_quick_subscribe form input {
    text-align: center;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 #a0a0a0 inset;
    color: #5a5a5a;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.85em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #008990;
    color: #5a5a5a;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0 1.7em;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.56666667em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .sidebar .newsletter_quick_subscribe {
    margin-top: 1.7em;
    padding: 1.40625rem 0.9375rem;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-right: 0.34em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #5a5a5a;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(90, 90, 90, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.7em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content a {
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.7em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded {
    color: #008990;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a {
    color: #008990;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #5a5a5a;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 2.75rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    color: #008990;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    margin-left: 0.85em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #5a5a5a;
    display: block;
    padding: 0.425em 1.7em 0.425em 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    color: #008990;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.85em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.85em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.425em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.7em;
  }
  .popup_close {
    background: #008990;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.48571429em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(90, 90, 90, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #f3f3f3;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(243, 243, 243, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.7em 3.4em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.7em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.7em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.7em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.85em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.7em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #008990;
    border: 0.0625rem solid #008990;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #00585d;
    border: 0.0625rem solid #00585d;
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #00585d;
    border: 0.0625rem solid #00585d;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.85em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f3f3f3;
    border-radius: 0;
    color: #5a5a5a;
    margin-bottom: 0.85em;
    padding: 1.13333333em 1.7em;
  }
  .opening_times .title {
    padding-top: 0.85em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.55em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #a0a0a0;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background: #008990;
    border: 0.0625rem solid #008990;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 400;
    padding: 0.425em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.56666667em;
  }
  form[role="search"] button[disabled] {
    background-color: #f3f3f3;
    border-color: #e6e6e6;
    color: #dadada;
  }
  form[role="search"] button[disabled] .icon {
    fill: #5a5a5a;
  }
  form[role="search"] button:focus {
    position: relative;
    z-index: 10;
    background: #00585d;
    border-color: #00585d;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #a0a0a0;
    border-radius: 0 0 0 0;
    color: #5a5a5a;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.85em;
    -webkit-transition: background 300ms, border-color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, outline 300ms, z-index 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #008990;
    color: #5a5a5a;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.85em;
    padding-top: 0.425em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    height: 2.125rem;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #008990;
  }
  .toggle_search_box a {
    fill: #5a5a5a;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.85em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: #008990;
    border: 0.0625rem solid #008990;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(90, 90, 90, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.25rem;
    position: absolute;
    right: 1.7em;
    top: 1.7em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #00585d;
    border-color: #00585d;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 1.25rem rgba(90, 90, 90, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.425em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .steuernews_content [href*="thema"] {
    color: #5a5a5a;
  }
  .steuernews_preview {
    margin-bottom: 0.85em;
  }
  .steuernews_preview .entry {
    border-bottom: 0.0625rem solid #f3f3f3;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.7em;
  }
  .steuernews_preview .entry span.main_color {
    display: inline-block;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview .entry:hover .title {
    color: #5a5a5a;
  }
  .steuernews_preview .entry:hover span.main_color {
    -webkit-transform: translateX(0.1875rem);
            transform: translateX(0.1875rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview .entry:hover .image img {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview a.touch_link .title {
    text-decoration: none;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    margin-right: 1.7em;
    opacity: 0.7;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_issues {
    margin-bottom: 1.7em;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_issues ul li {
    margin-right: 3.4em;
    min-width: 10rem;
  }
  .steuernews_issues ul li a {
    text-decoration: none;
  }
  .steuernews_issues ul li a.active,
  .steuernews_issues ul li a:hover {
    color: #008990;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.7em;
  }
  .news_image img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_newsletter_subscribe {
    background: #f3f3f3;
    border-radius: 0;
    margin-bottom: 1.7em;
    padding: 1.7em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.7em 0 0.85em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.85em;
  }
  .steuernews_current_issue li a {
    text-decoration: none;
  }
  .steuernews_current_issue li a.active,
  .steuernews_current_issue li a:hover {
    color: #008990;
  }
  .highlight_keyword {
    background: #008990;
    color: #fff;
    outline: 0.125rem solid #008990;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.85em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.7em 0.85em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.85em;
  }
  .team_entry .photo {
    margin-bottom: 0.85em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.85em;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f3f3f3;
    border: 0.0625rem solid #a0a0a0;
    border-radius: 0;
    color: #5a5a5a;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.375rem;
    padding: 0.425em 0.85em;
    position: absolute;
    text-align: center;
    top: -3.625rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f3f3f3;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #a0a0a0;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .open_street_map .map {
    background-color: #f3f3f3;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(243, 243, 243, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.7em 3.4em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.7em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.7em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.7em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.85em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.7em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #008990;
    border: 0.0625rem solid #008990;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #00585d;
    border: 0.0625rem solid #00585d;
    box-shadow: 0 0 0 0.1875rem rgba(0, 137, 144, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #00585d;
    border: 0.0625rem solid #00585d;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.85em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f3f3f3;
    border-radius: 0;
    color: #5a5a5a;
    margin-bottom: 0.85em;
    padding: 1.13333333em 1.7em;
  }
}
@media screen and (max-width: 29.9375rem) {
  .contact .email {
    font-size: 86%;
  }
}
@media screen and (min-width: 18.125rem) {
  aside .teaser_overview .teaser {
    padding: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  aside .teaser_overview .teaser {
    padding: 4.6875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .author_ver {
    left: 2.8125rem;
    top: 2.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.85em;
  }
}
@media screen and (min-width: 47.5rem) {
  .logo_wrapper {
    padding: 3.4em 0;
  }
}
@media screen and (min-width: 18.125rem) {
  .logo_wrapper .company_logo {
    max-width: calc(12.5rem + 2.5
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .logo_wrapper .company_logo {
    max-width: 15rem;
  }
}
@media screen and (min-width: 18.125rem) {
  .cookie_banner .close > span {
    min-height: calc(2.625rem + 0
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 18.125rem) {
  footer {
    margin-top: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  footer {
    margin-top: 6.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer {
    padding: 5.1em 0 2.55em;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .related_links {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (max-width: 29.9375rem) {
  footer .container {
    padding: 0;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 18.125rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 18.125rem)
                            / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 18.125rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 18.125rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 18.125rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 18.125rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.row_reverse_xlarge {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 18.125rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 18.125rem) {
  .navigation_wrapper {
    margin-bottom: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .navigation_wrapper {
    margin-bottom: 3.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_picture {
    margin-top: -5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_picture {
    margin-top: -8.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.7em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: block;
  }
  .u_online_benefits_flow .arrow_small {
    display: none;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.85em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.85em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer .arrow_large {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.7em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.4em;
            column-gap: 3.4em;
  }
}
@media screen and (min-width: 18.125rem) {
  .startpage .picture_wrapper {
    margin: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125)) 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .picture_wrapper {
    margin: 3.125rem 0 4.6875rem;
  }
}
@media screen and (min-width: 75rem) {
  .startpage .figure {
    margin-bottom: 0;
    -webkit-transform: translateX(2.8125rem);
            transform: translateX(2.8125rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
}
@media screen and (min-width: 75rem) and (min-width: 93.75rem) {
  .startpage .figure {
    margin-left: 1.7em;
  }
}
@media screen and (min-width: 18.125rem) {
  .startpage .teaser_overview .teaser {
    padding: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .teaser_overview .teaser {
    padding: 4.6875rem;
  }
}
@media screen and (min-width: 75rem) {
  .startpage .teaser_overview .teaser {
    padding: 1.7em 1.7em 2.55em 3.06em;
  }
}
@media screen and (min-width: 75rem) and (min-width: 87.5rem) {
  .startpage .teaser_overview .teaser {
    margin-right: 1.7em;
    padding: 2.55em 3.06em 4.25em;
  }
}
@media screen and (min-width: 75rem) and (min-width: 93.75rem) {
  .startpage .teaser_overview .teaser {
    margin-right: 3.4em;
  }
  .startpage .teaser_overview .teaser .button {
    margin-top: 3.4em;
  }
}
@media screen and (min-width: 75rem) and (min-width: 103.125rem) {
  .startpage .teaser_overview .teaser {
    margin-right: 5.1em;
    padding: 3.4em 3.06em 5.1em;
  }
}
@media screen and (min-width: 106.25rem) {
  .startpage .teaser_overview .teaser p {
    font-size: 103%;
  }
}
@media screen and (min-width: 18.125rem) {
  .newsletter_quick_subscribe {
    padding: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .newsletter_quick_subscribe {
    padding: 4.6875rem;
  }
}
@media screen and (min-width: 35rem) {
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
  .newsletter_quick_subscribe form input {
    text-align: left;
  }
}
@media screen and (min-width: 18.125rem) {
  .sidebar .newsletter_quick_subscribe {
    padding: calc(0.9375rem + 2.1875
                                     * (100vw - 18.125rem)
                                     / (93.75 - 18.125));
  }
}
@media screen and (min-width: 93.75rem) {
  .sidebar .newsletter_quick_subscribe {
    padding: 4.6875rem 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .sidebar .newsletter_quick_subscribe {
    margin-top: 0;
  }
  .sidebar .newsletter_quick_subscribe form {
    display: block;
  }
  .sidebar .newsletter_quick_subscribe form input {
    text-align: center;
  }
  .sidebar .newsletter_quick_subscribe form button {
    width: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.7em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.7em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_issues {
    margin-bottom: 3.4em;
  }
}
@media screen and (max-width: 47.5rem) {
  .news_image {
    display: none;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 47.5rem) {
  [role="tooltip"] {
    top: -2.4375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.7em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.7em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.85em;
    max-width: 11.25rem !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.7em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.85em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    border: 0;
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.7em;
  }
  .company_logo {
    display: block;
    margin-bottom: 1.7em;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .contact_wrapper .address > span,
  .contact_wrapper span.contact {
    display: block;
  }
  .icon {
    display: none;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.7em 0 0;
    padding: 0.85em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
